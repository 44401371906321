// Import Bootstrap and its default variables


// Override default variables before the import
// $body-bg: rgb(148, 148, 121); // c2b9b3
@import "bootstrap/scss/functions"; // do this, otherwise some stuff does not render right (product overview cart buttons for example)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$theme-colors: (
    "primary": #2f5896,
    "danger": #ff4136,
    "navbar": #222222,
    "primary-bg-subtle": #d74242,
);

.hr {
    border-top: 1px solid #83817c;
    padding: 20px;
}

[data-bs-theme="dark"] {
    --bs-body-bg: #292828 !important;
    // --bs-body-bg: #292828 !important;
    --bs-body-color: #c9c9c9 !important;

    --my-theme-color: #1c5480;
    --my-theme-color-bright: #eeefef;
    --my-text-color: #cecfd0;
    --my-link-color: #7ea9b4;
    --my-border-color: #393939;
    --my-nav-background: #962f2f;
    --my-title-background:#1c5480FF;

    .overlay {
        opacity: 1.0f;
        background-image: url("noise.png");
    }

    a {
        text-decoration: none;
        color: var(--my-link-color);
    }

    img {
        border-radius: 3px;
        //border: 1px solid #454545;
        box-shadow: 0 20px 20px 0 rgba(29, 27, 27, 0.15), 30px 30px 34px 0 #0d0d0d30;
    }

    .site-footer_followustitle {
        color: #b3b2b1;
        padding: 20px 0px 0px 10px;
    }

    .site-footer_icon {
        border-radius: 7px;
        background-color: #ffffff33;
        box-shadow: 0 0px 0px 0 rgba(29, 27, 27, 0.15), 0px 0px 0px 0 #0d0d0d25;

        filter: invert(0.5);
        width: 50px;
        height: 50px;
        padding: 10px;
        margin-right: 10px;
    }

    iframe {
        border-radius: 3px;
        border: 1px solid #454545;
        box-shadow: 0 3px 3px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 #0d0d0d55;
    }

    a:hover {
        color: #ddd9d0;
    }

    .form-control::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(149, 149, 149);
        opacity: 1;
        /* Firefox */
    }

    .form-control {
        background-color: rgba(255, 255, 255, 0.0) !important;
        color: var(--my-text-color);
    }

    .form-control:focus {
        background-color: rgba(255, 255, 255, 0.0) !important;
        color: var(--my-text-color);
    }

    .form-group {
        padding: 10px 0px;
    }

    .plugin-title
    {
        @extend .overlay;
        // font-size: 2.8em;
        font-size: calc(1.7em + min(2.7vw, 1.1em));
        font-weight: 900;
        line-height: 1.2;
        color: #c2c2c2;
        text-shadow: 0px 0px 60px #00000060;
        text-align: right;
        // background-color: var(--my-title-background);
        // background-color: var(--my-title-background);
        padding-top: 4px;
        width: 100%;
        white-space:nowrap;

        position: absolute;
        top: -64px;
        right: 6px;
    }

    .plugin-image
    {
        margin-top: 80px;
        margin-bottom: 6%;
    }

    .col-lg-8-productPage {
        @extend .col-lg-8;
        margin: 0px;
        padding: 0px;
    }

    .parallax {
        height: 100hv;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-perspective: 1px;
        perspective: 1px;
    }

    .parallaxLayer {
        top: 0;
        position: sticky;
        // position: absolute;
        transform: translateZ(0) scale(1);
    }

    // odd behaviour that click does not work when 
    // clicking on material icon inside the button
    // https://stackoverflow.com/questions/61031541/in-react-a-button-with-icon-does-not-trigger-function
    i.material-icons{
        pointer-events: none;
    }

    .well {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;
    }

    .row {
        max-width: 1400px;
        width: 100%;
        margin: 0px auto;
    }

    .row.content {
        width: 95%; // product page rows, leave a small gap
    }

    .container {
        max-width: 100%;
        padding: 0;
    }

    .navbar-nav {
        font-size: 1.1em;
        padding-left: 0px;
        padding-right: 0px;
    }

    a.nav-link {
        margin-left: 10px;
        margin-right: 10px;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    }

    .navbar-toggler {
        margin-right: 20px;
    }

    .no_shadow {
        box-shadow: none;
    }

    .navbar-dark {
        background-color: #212020;
        padding: 8px 0px;
    }

    .navbar {
        border-bottom: 1px solid var(--my-border-color);
        // box-shadow: none; // 0 6px 6px 0 rgba(29, 27, 27, 0.15), 10px 10px 10px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .material-icons {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        margin-top: -2px;
        font-size: 1.0em;
      }

    tal-shopprice
    {
        display: block;
        padding: 4px;
        font-size: 0.9em;
        color: #b2b2b2;
    }

    .simpletableLicense
    {
        background: #353535;
        padding: 10px;
        border-radius: 2px;
    }

    .table > :not(caption) > * > * {
        background-color: #00000000;
    }

    thead
    {
        background: #00000011;
    }

    tbody
    {
        background: #00000011;
    }

    .table.simpletableDeviceOrder > thead
    {
        background: #454545;
    }

    th
    {
        color: #dfdfdf;
        font-weight: 600;
    }

    .table
    {
        --bs-table-color: #b1b1b1;
        --bs-table-striped-color: #b1b1b1;
    }

    .cart-item-delete-button
    {
        background-color: #00000000;
        --bs-btn-bg: #00000000;
        --bs-btn-hover-bg: #00000000;
        border: none;
        color: rgb(156, 156, 156);
        margin-bottom: 4px;
        --bs-btn-hover-color: #e91414;
        margin-top: 1px;
    }

    .btn-primary.showCartButton {
       // @extend .nav-link;
        background-color: #32323200;
        --bs-btn-hover-bg: rgb(92, 92, 92);
        --bs-btn-hover-color: rgb(223, 223, 223);
        --bs-btn-active-color: rgb(169, 169, 169);
        --bs-btn-active-bg: #123855;
        font-size: 1.1em;
        border: none;
        margin-right: 70px;
        margin-left: 10px;
        width: 40px;
    }

    // remover arrow near drop down
    .dropdown-toggle::after {
        display:none;
    }

    .productPageTitleBackground {
        @extend .overlay;
        background-color: #AA99;
        padding: 0px;
        max-width: 100%;
        padding-right: 0px;
        box-shadow: inset 0px 0cap 60px 60px #12121222;
    }

    .productPageDownloadCol {
        @extend .overlay;
        background-color: #00000095;
        min-width: 330px;
    }

    .producttitle {
        @extend .overlay;
        background-color: var(--my-title-background);
        padding-top: 10px;
        padding-left: 20px;
        max-width: 100%;
        box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .newstitle {
        @extend .overlay;
        background-color: var(--my-title-background);
        padding-top: 20px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        max-width: 100%;
        box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .productboxImage {
        object-fit: none;
        border-radius: 2;
        width: 300px;
        height: 150px;
        margin-bottom: 6px;
    }

    .productbox {
        @extend .overlay;
        // @extend .rounded;
        border-radius: 1;
        width: 300px;
        height: auto;
        background-color: #AA99;
        margin: 4px;
        padding: 10px;
        //box-shadow: none; // 0 6px 6px 0 rgba(29, 27, 27, 0.15), 10px 10px 7px 0 rgba(7, 7, 7, 0.2); //, inset 0px 6px 6px 6px #00000012;
        background: linear-gradient(to bottom, #AA99 170px, #434343 170px);
        box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .productdescription {
        width: 300px;
        white-space: nowrap;
    }

    .productbuttonspace {
        height: 34px;
        width: 310px;
    }

    .productbutton {
        width: 104px;
        height: 32px;
        float: right;
        padding: 0;
        position: relative;
        bottom: 38px;
        right: 30px
    }

    footer.site-footer {
        padding-left: 20px;
        position: absolute;
        bottom: -0px;
        background: #212020;
        width: 100%;
        color: #68655f;
        height: 140px;
        /*      Footer height */
        border-top: 1px solid var(--my-border-color);
        background-image: url("noise.png");
        box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 2px -10px 7px 0 rgba(7, 7, 7, 0.08); //, inset 0px 6px 6px 6px #00000012;
    }

    .footer_container {
        max-width: 1240px;
        color: #635c5c;
    }

    #content-wrap {
        padding-bottom: 180px;
        /*     Footer height */
    }

    .row {
        //margin: 0px 0px 0px 0px;
        padding: 0px;
    }

    .tab-content {
        margin-left: -20px;
    }

    .accordion-button:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff44'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

    .accordion {
        --bs-accordion-bg: #ffFFFF00;
        --bs-accordion-active-color: var(--my-theme-color);
        --bs-accordion-border-color: #ffFFFF00;
        border: 0;
        --focus-ring-width: 1px;
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .accordion-item:last-of-type .accordion-button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .accordion-button:focus {
        z-index: 3;
        border: 0;
        border-color: #ffFFFF00;
        box-shadow: none;
    }

    .accordion-body {
        padding: 20px 0px;
    }

    .accordion-button {
        --bs-accordion-active-bg: #ffFFFF00;
        border: 0;
        padding: 6px 0px;
    }

    .accordion-button:not(.collapsed) {
        color: var(--my-theme-color-bright);
    }

    // Tab-Pane style 
    a.nav-link {
        display: block;
        padding: 8px;
        color: #e8e8e8aa;
        font-weight: 400;
        white-Space: nowrap;
    }

    .navbar-collapse {
        text-align: center;
    }

    .nav-pills .nav-link.active {
        color: var(--my-theme-color-bright);
        font-weight: 400;
    }

    .nav-link:hover {
        color: #DDDDDDFF;
    }

    .pluginformat {
        @extend .overlay;
        background-color: #4b4b4b;
        color: #adadad;
        padding: 6px;
        font-weight: 400;
        font-size: 1.0em;
        border: none;
        margin: -3px;
    }

    // register cards
    .nav-pills {
        @extend .overlay;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: var(--my-nav-background);
        padding: 0px;
        box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        @extend .overlay;
        background-color: #2c292900;
        padding: 10px 20px;
        // box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
        //border: 1px solid var(--my-border-color);
    }

    .nav-pills .nav-link,
    .nav-pills .show>.nav-link {
        //background-color: #2c2929;
        padding: 10px 20px;
        // box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 12px 12px 12px 0 rgba(0, 0, 0, 0.1);
        //border: 1px solid var(--my-border-color);

    }

    // for transfer license
    .col-sm {
        padding: 0px;
    }

    .btn-primary {
        // @extend .overlay;
        color: #BBBBBBFF;
        --bs-btn-color: #BBBBBBFF;
        --bs-btn-bg: var(--my-theme-color);
        //--bs-btn-border-color: var(--my-border-color);
        --bs-btn-hover-color: #DDDDDDFF;
        --bs-btn-hover-bg: var(--my-theme-color);
        --bs-btn-hover-border-color: #FFFFFF00;
        --bs-btn-focus-shadow-rgb: #FFFFFF00;
        --bs-btn-active-color: rgb(5, 4, 4);
        --bs-btn-active-bg: #123855;
        // --bs-btn-active-border-color: var(--my-border-color);
        --bs-btn-active-border-color: none;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(185, 185, 185, 0.5);
        --bs-btn-disabled-color: #000;
        --bs-btn-disabled-bg: #d7424200;
        //--bs-btn-disabled-border-color: var(--my-border-color);
        --bs-btn-disabled-border-color: none;
        font-weight: 500;
        // box-shadow: 0 6px 6px 0 rgba(29, 27, 27, 0.15), 5px 5px 5px 0 rgba(0, 0, 0, 0.0);
    }

    .btn-secondary {
        // @extend .overlay;
        color: #BBBBBBFF;
        --bs-btn-color: #BBBBBBFF;
        --bs-btn-bg: #484747;
        //--bs-btn-border-color: var(--my-border-color);
        --bs-btn-hover-color: #DDDDDDFF;
        --bs-btn-hover-bg: rgb(139, 139, 139);
        --bs-btn-hover-border-color: var(--my-border-color);
        --bs-btn-focus-shadow-rgb: #FFFFFF00;
        --bs-btn-active-color: #DDDDDDFF;
        --bs-btn-active-bg: #bbbaba;
        --bs-btn-active-border-color: var(--my-border-color);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(185, 185, 185, 0.0);
        --bs-btn-disabled-color: #BBBBBBFF;
        --bs-btn-disabled-bg: #48474777;
        --bs-btn-disabled-border-color: var(--my-border-color);
        --bs-btn-line-height: 1;
        font-weight: 500;
    }
}

// fast spring transparent background
#fsc-popup-frame {
    color-scheme: normal;
}

.fs-popup-background {
    // background: #00000077 !important;
}

iframe {
    // height:"200px";
    // background-color: #292929;
}

$font-size-base: 1.2rem;

@mixin no-padding($side) {
    @if $side =='all' {
        .no-padding {
            padding: 0 !important;
        }
    }

    @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';
@import "bootstrap";