html, body, p {
  /*font-family: 'Ubuntu Mono', monospace;*/
  /*font-family: 'Coda', static !important;*/
  font-family: 'Oxanium' !important;
  line-height: 2.0;
  font-size:19px;
  font-weight: 400;
}

h1, h2
{
  font-family: 'Oxanium' !important;
  font-weight:600;
}

h3, h4, h5, h6
{
  font-family: 'Oxanium' !important;
  font-weight:400;
}

strong, b
{
  font-weight:550;
}


/*
@font-face {
  font-family: 'Agdasima', static !important;;
  font-style: normal;
  font-stretch: 300%;
  src: url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Coda:wght@400;800&family=Jockey+One&family=Michroma&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}*/

.darktext
{
  color:#414141
}

#page-container 
{
  position: relative;
  min-height: 100vh;
  margin: 0 auto; 
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(210, 203, 203);
  opacity: 1; /* Firefox */
}

.site-footer_followus {
  color: #68655f;
}

code 
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.no_shadow {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
  box-shadow: none;
  border: none;
}

/*
textarea {
  background-color:rgba(255,255,255,0.4) !important;
}

input{
  background-color:rgba(255,255,255,0.4) !important;
}*/

.form-control {
  background-color: rgba(255,255,255,0.4) !important;
  color: black;
}

.form-control:focus {
  color: #000000;
}

.tab-pane
{
  padding: 20px;
}

.center {
  margin: auto;
  width: 100%;
  padding: 10px;
}

.lb-outerContainer {
  background-color: black;
  border: 1px solid rgb(85, 83, 83);
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.isResizing {
  background: #FFFFFF22;
}

.resizer {
  display: inline-block;
  /* border-right: 1px solid rgb(84, 84, 84);*/
  width: 40px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  /* prevent scroll on touch devices */
  touch-action: none;
}